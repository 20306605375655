import { omit } from 'lodash';

import {
  CancelStatusDetails,
  DisputeStatusDetails,
  OrderStatus,
  PayoutDisputeStatusDetails,
  PayoutOrderStatus,
  PayoutRejectStatusDetails,
} from 'enums';

export const TRADER_SELECT_DISPUTE_DETAILS = [
  DisputeStatusDetails.DisputeNoPayment,
  DisputeStatusDetails.DisputeDifferentAmount,
] as DisputeStatusDetails[];

export const PAYOUT_DISPUTE_SELECT_DETAILS = [
  PayoutDisputeStatusDetails.DisputeInvalidRequisites,
  PayoutDisputeStatusDetails.DisputePaymentFailed,
];

export const PAYOUT_REJECT_SELECT_DETAILS = [
  PayoutRejectStatusDetails.RejectNoFunds,
  PayoutRejectStatusDetails.RejectPaymentImpossible,
  PayoutRejectStatusDetails.RejectRequisitesBlocked,
];

export const ORDER_WITH_RECEIPTS_STATUSES = [
  OrderStatus.CustomerConfirm,
  OrderStatus.TraderConfirm,
  OrderStatus.Completed,
  OrderStatus.Cancelled,
  OrderStatus.Dispute,
  OrderStatus.Error,
];

export const VISIBLE_CANCEL_STATUS_DETAILS = omit(CancelStatusDetails, [
  CancelStatusDetails.CancelledRequisitesVerificationOrder,
]);

export const MERCHANT_ORDER_STATUSES = [
  OrderStatus.Completed,
  OrderStatus.Cancelled,
  OrderStatus.Dispute,
];

export const TRADER_ORDER_STATUSES = [
  OrderStatus.CustomerConfirm,
  OrderStatus.TraderConfirm,
  OrderStatus.Completed,
  OrderStatus.Cancelled,
  OrderStatus.Dispute,
  OrderStatus.Error,
];

export const TRADER_PAYOUT_ORDER_STATUSES = [
  PayoutOrderStatus.TraderPayment,
  PayoutOrderStatus.Completed,
  PayoutOrderStatus.Cancelled,
  PayoutOrderStatus.Dispute,
];

// we don't like to show a timer when more than 15 minutes left
// due to cron runs every minute and we like show a close sync as possible
const MINUTE = 60 * 1000;
export const SHOW_TIMER_WHEN_LESS = 15 * MINUTE;
export const DEFAULT_PAYOUT_MAX_TIMEOUT = 60;
export const PAYIN_DISPUTE_ALERT_AFTER = 30 * MINUTE;
export const HIDE_PAYOUT_TIMER_IN_STATUSES = [
  PayoutOrderStatus.Cancelled,
  PayoutOrderStatus.Completed,
  PayoutOrderStatus.Error,
];

export const DEFAULT_ASSET_CURRENCY_CODE = 'USDT';
