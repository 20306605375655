import moment from 'moment';
import React from 'react';

import { Timer } from 'components';
import { PAYIN_DISPUTE_ALERT_AFTER } from 'constants/order.constants';
import { PayinOrder } from 'types';

type Props = {
  order: PayinOrder;
};

export const OrderDisputeTimeColumn: React.FC<Props> = ({ order }) => (
  <Timer
    startTime={moment(order.statusAt).toDate()}
    alertAfter={PAYIN_DISPUTE_ALERT_AFTER}
    showTimePassed
    sx={{ mt: 1 }}
  />
);
