import { Sync as SyncIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { DateLabel } from 'components';
import { useMoney } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutRequisitesAutomation } from 'types';
import { formatUtils } from 'utils';

type Props = {
  automation: PayoutRequisitesAutomation;
  disabled?: boolean;
  getAccountInfo: () => void;
};

export const PayoutAutomationAccountInfoDetails: React.FC<Props> = ({
  automation,
  disabled,
  getAccountInfo,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.payout_requisites_automation.account_info',
  });
  const { getFormattedMoney } = useMoney();

  return (
    <div>
      <div className="tw-flex tw-items-center">
        {automation?.accountInfo && (
          <Fragment>
            {automation.accountInfo.balance && (
              <div className="tw-mr-1">
                <div>
                  {`${t('balance')}: ${getFormattedMoney({
                    value: automation.accountInfo.balance,
                    fiatCurrencyId: automation.balanceFiatCurrencyId,
                    symbol: true,
                  })}`}
                </div>
              </div>
            )}
          </Fragment>
        )}
        <IconButton
          color="primary"
          disabled={disabled}
          onClick={getAccountInfo}
        >
          <SyncIcon />
        </IconButton>
      </div>
      {automation?.accountInfoAt && (
        <DateLabel>
          {formatUtils.formatDate(automation.accountInfoAt)}
        </DateLabel>
      )}
    </div>
  );
};
