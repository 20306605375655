import {
  Check as CheckIcon,
  Warning as WarningIcon,
  HourglassEmpty as HourglassEmptyIcon,
} from '@mui/icons-material';
import { AxiosError } from 'axios';
import i18next from 'i18next';
import { findLast, includes, isString, map } from 'lodash';
import moment from 'moment';
import { Fragment } from 'react';

import {
  ContactInfo,
  CopyTextId,
  DataGridColumnDefinition,
  DateLabel,
  Money,
  RequisitesInfo,
  Timer,
  UserInfo,
  OrderStatusDetailsInfo,
} from 'components';
import { DEFAULT_PAYOUT_MAX_TIMEOUT } from 'constants/order.constants';
import {
  AdditionalOrderColumn,
  CallbackUrlStatus,
  CancelStatusDetails,
  DateFormat,
  FilterDefinitionType,
  OrderInternalStatusDetails,
  OrderRequisitesVerificationStatus,
  OrderStatus,
  OrderStatusDetails,
  PayoutOrderAutomationStatus,
  OrderStatusReason,
  PayoutOrderStatus,
  PayoutOrderStatusDetails,
  UserRole,
  OrderType,
  OrderDisputeType,
} from 'enums';
import {
  OrderAmountColumn,
  OrderCancelledToDisputeColumn,
  OrderResolveDisputeColumn,
  OrderStatusColumn,
  AutomationStatus,
  P2PProviderStatus,
  VerificationStatus,
  OrderColorCodeSelect,
  OrderDisputeTimeColumn,
} from 'features/orders';
import {
  PayoutOrderCancelledToDisputeColumn,
  PayoutOrderDisputeColumn,
  PayoutOrderStatusColumn,
  PayoutOrderValidationColumn,
  PayoutOrderAutomationStatusIcon,
} from 'features/payout-orders';
import {
  FilterDefinition,
  OrderFilters,
  PayinOrder,
  PayoutOrder,
  Order,
  CommonFilters,
  PayoutOrderFilters,
  OrderStatusHistory,
  PayoutOrderStatusHistory,
} from 'types';
import { formatUtils, formUtils, validationUtils } from 'utils';

type Status = OrderStatus | PayoutOrderStatus;
type StatusHistory = OrderStatusHistory | PayoutOrderStatusHistory;

const getCommonFilters = (
  filters: CommonFilters,
): FilterDefinition<OrderFilters>[] => {
  const { t } = i18next;
  return [
    {
      label: t('filters.date_from'),
      name: 'from',
      type: FilterDefinitionType.DateTime,
      startOf: 'minute',
      maxDate: filters?.to ? moment(filters?.to) : undefined,
      getDisplayName: (value: string) =>
        formatUtils.formatDate(value, DateFormat.Long),
    },
    {
      label: t('filters.date_to'),
      name: 'to',
      type: FilterDefinitionType.DateTime,
      endOf: 'minute',
      minDate: filters?.from ? moment(filters?.from) : undefined,
      getDisplayName: (value: string) =>
        formatUtils.formatDate(value, DateFormat.Long),
    },
    {
      label: t('features.orders.filters.order_id'),
      name: 'orderId',
      type: FilterDefinitionType.Text,
      format: 'uuid',
    },
  ];
};

const getCommonPayoutOrderFilters = (
  filters: CommonFilters,
): FilterDefinition<PayoutOrderFilters>[] => {
  const { t } = i18next;
  return [
    {
      label: t('filters.date_from'),
      name: 'from',
      type: FilterDefinitionType.DateTime,
      startOf: 'minute',
      maxDate: filters?.to ? moment(filters?.to) : undefined,
      getDisplayName: (value: string) =>
        formatUtils.formatDate(value, DateFormat.Long),
    },
    {
      label: t('filters.date_to'),
      name: 'to',
      type: FilterDefinitionType.DateTime,
      endOf: 'minute',
      minDate: filters?.from ? moment(filters?.from) : undefined,
      getDisplayName: (value: string) =>
        formatUtils.formatDate(value, DateFormat.Long),
    },
    {
      label: t('features.orders.filters.order_id'),
      name: 'id',
      type: FilterDefinitionType.Text,
      format: 'uuid',
    },
  ];
};

const getCallbackUrlStatusLabel = (status: CallbackUrlStatus) => {
  const { t } = i18next;
  const key = `features.orders.callback_url_status.${status}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getStatusLabel = (status: string) => {
  const { t } = i18next;
  const key = `order_status.${status}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getStatusFilterDefinition = (
  statuses: (OrderStatus | PayoutOrderStatus)[],
): FilterDefinition<OrderFilters> => {
  const { t } = i18next;
  return {
    label: t('features.orders.filters.status'),
    name: 'statuses',
    type: FilterDefinitionType.MultiSelect,
    options: map(statuses, (status) => ({
      value: status,
      label: getStatusLabel(status),
    })),
    getDisplayName: (value: OrderStatus[]) =>
      map(value, (status) => getStatusLabel(status)).join(', '),
  };
};

const getOrderRequisitesVerificationStatusLabel = (
  status: OrderRequisitesVerificationStatus,
) => {
  const { t } = i18next;
  return t(`order_requisites_verification_status.${status}`, {
    defaultValue: status,
  });
};

const getOrderRequisitesVerificationStatusOptions = () =>
  formUtils.getOptions(
    map(OrderRequisitesVerificationStatus, (status) => ({
      id: status,
      name: getOrderRequisitesVerificationStatusLabel(status),
    })),
  );

const getStatusDetailsLabel = (details: OrderStatusDetails) => {
  const { t } = i18next;
  const key = `order_status_details.${details}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getPayoutStatusDetailsLabel = (details: PayoutOrderStatusDetails) => {
  const { t } = i18next;
  const key = `order_status_details.${details}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const hadStatus = (
  order: Order,
  status: Status,
  statusHistory?: StatusHistory[],
) =>
  order.status === status ||
  statusHistory?.some((history) => history.status === status);

const isCustomerPaid = (order: PayinOrder, statusHistory?: StatusHistory[]) =>
  hadStatus(order, OrderStatus.TraderConfirm, statusHistory) ||
  hadStatus(order, OrderStatus.Completed, statusHistory);

const isTraderPaid = (order: PayoutOrder, statusHistory?: StatusHistory[]) =>
  hadStatus(order, PayoutOrderStatus.Completed, statusHistory);

const renderP2PProviderOrderRequisites = (order: PayinOrder) => {
  if (order?.p2pProviderOrder?.requisites) {
    const p2pProviderRequisites = order.p2pProviderOrder.requisites;
    return (
      <RequisitesInfo
        cardholder={p2pProviderRequisites.cardholder}
        bankId={p2pProviderRequisites.bank ? undefined : order.bankId}
        bankCode={p2pProviderRequisites.bank}
        paymentTypeId={order.paymentTypeId}
        cardInfo={p2pProviderRequisites.cardInfo}
        phone={p2pProviderRequisites.phone}
        swiftBic={p2pProviderRequisites.swiftBic}
        bic={p2pProviderRequisites.bic}
        email={p2pProviderRequisites.email}
        idCard={p2pProviderRequisites.idCard}
        beneficiaryName={p2pProviderRequisites.beneficiaryName}
        accountNumber={p2pProviderRequisites.accountNumber}
        expirationDate={p2pProviderRequisites.expirationDate}
        taxId={p2pProviderRequisites.taxId}
      />
    );
  }
};

const renderP2PProviderPayoutOrderRequisites = (order: PayoutOrder) => {
  if (order?.p2pProviderOrder?.requisites) {
    return null;
  }
};

const renderOrderRequisites = (order: PayinOrder) => {
  if (order?.requisites) {
    return <RequisitesInfo requisite={order.requisites} />;
  }

  if (order?.p2pProviderOrder?.requisites) {
    return renderP2PProviderOrderRequisites(order);
  }
};

const getStatusDate = (
  order: Order,
  status: string,
  statusHistory?: StatusHistory[],
) =>
  order.status === status
    ? order.statusAt
    : findLast(statusHistory, (history) => history.status === status)?.statusAt;

const getPayoutOrderColumns = (
  additionalColumns: AdditionalOrderColumn[],
  role: UserRole,
): DataGridColumnDefinition<PayoutOrder>[] =>
  getOrderColumns(additionalColumns, OrderType.Payout, { role });

const getPayinOrderColumns = (
  additionalColumns: AdditionalOrderColumn[],
  params: { role: UserRole; requisitesVerificationOrders?: boolean },
): DataGridColumnDefinition<PayinOrder>[] =>
  getOrderColumns(additionalColumns, OrderType.Payin, params);

const getOrderColumns = (
  additionalColumns: AdditionalOrderColumn[],
  orderType: OrderType,
  params: { role?: UserRole; requisitesVerificationOrders?: boolean } = {},
): DataGridColumnDefinition<Order>[] => {
  const { t } = i18next;
  const prefix = `features.orders.table.columns`;
  const isPayin = orderType === OrderType.Payin;
  const isPayout = orderType === OrderType.Payout;
  const isManager = includes(
    [UserRole.Admin, UserRole.TechOperator, UserRole.Operator],
    params?.role,
  );

  return [
    {
      header: t(`${prefix}.id`),
      valueGetter: (item) => (
        <div className="tw-flex">
          <div>
            <CopyTextId id={item.id} />
            <DateLabel>{formatUtils.formatDate(item.createdAt)}</DateLabel>
            {isManager && (
              <P2PProviderStatus order={item as PayinOrder} sx={{ mt: 1 }} />
            )}
            {isPayin && <VerificationStatus order={item as PayinOrder} />}
          </div>
          {isManager && (
            <div className="tw-ml-4 tw-mt-2">
              <OrderColorCodeSelect order={item} orderType={orderType} />
            </div>
          )}
        </div>
      ),
    },
    {
      header: t(`${prefix}.currency_rate`),
      hidden: params.requisitesVerificationOrders,
      valueGetter: (item) => {
        if (
          hideSensitiveData({
            isPayout,
            role: params?.role,
            status: item.status,
          })
        ) {
          return null;
        }

        if (isPayin && item.originCurrencyRate && isManager) {
          const payinOrder = item as PayinOrder;
          return (
            <Fragment>
              {payinOrder.warnings?.currencyRate && (
                <WarningIcon className="tw-mr-1" color="warning" />
              )}
              <Money
                value={payinOrder.currencyRate}
                fiatCurrencyId={payinOrder.fiatCurrencyId}
                symbol
              />
            </Fragment>
          );
        }

        return (
          <Money
            value={item.currencyRate}
            fiatCurrencyId={item.fiatCurrencyId}
            symbol
          />
        );
      },
    },
    {
      header: t(`${prefix}.amount`),
      hidden: params.requisitesVerificationOrders,
      valueGetter: (item) =>
        hideSensitiveData({
          isPayout,
          role: params?.role,
          status: item.status,
        }) ? null : (
          <OrderAmountColumn order={item} />
        ),
    },
    {
      header: t(`${prefix}.trader_requisites`),
      valueGetter: (item) => renderOrderRequisites(item as PayinOrder),
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.TraderCard),
    },
    {
      header: t(`${prefix}.customer_id`),
      valueKey: 'customerId',
      hidden: !additionalColumns.includes(AdditionalOrderColumn.CustomerId),
    },
    {
      header: t(`${prefix}.customer`),
      valueGetter: (item) => (
        <UserInfo name={item.customerName} id={item.customerId} />
      ),
      hidden: !additionalColumns.includes(AdditionalOrderColumn.Customer),
    },
    {
      header: t(`${prefix}.customer`),
      valueGetter: (item) =>
        hideSensitiveData({
          isPayout,
          role: params?.role,
          status: item.status,
        }) ? null : (
          <div>
            <UserInfo name={item.customerName} id={item.customerId} />
            <ContactInfo
              phone={item.customerPhone}
              email={item.customerEmail}
              telegram={(item as PayoutOrder).customerTelegram}
            />
          </div>
        ),
      hidden: !additionalColumns.includes(
        AdditionalOrderColumn.CustomerContacts,
      ),
    },
    {
      header: t(`${prefix}.customer_requisites`),
      valueGetter: (item) => {
        const payinOrder = item as PayinOrder;
        return (
          <Fragment>
            {(payinOrder?.paymentCustomerCardFirstDigits ||
              payinOrder?.paymentCustomerCardLastDigits) && (
              <div>
                {formatUtils.formatCardDigits({
                  firstDigits: payinOrder?.paymentCustomerCardFirstDigits,
                  lastDigits: payinOrder?.paymentCustomerCardLastDigits,
                })}
              </div>
            )}
            {payinOrder?.paymentCustomerPhoneLastDigits && (
              <div>
                {formatUtils.formatPhoneLastDigits(
                  payinOrder?.paymentCustomerPhoneLastDigits,
                )}
              </div>
            )}
          </Fragment>
        );
      },
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.CustomerRequisites),
    },
    {
      header: t(`${prefix}.customer_requisites`),
      valueGetter: (item) => {
        const payinOrder = item as PayinOrder;
        return (
          <Fragment>
            {(payinOrder?.paymentCustomerCardFirstDigits ||
              payinOrder?.paymentCustomerCardLastDigits) && (
              <div>
                {formatUtils.formatCardDigits({
                  firstDigits: payinOrder?.paymentCustomerCardFirstDigits,
                  lastDigits: payinOrder?.paymentCustomerCardLastDigits,
                })}
              </div>
            )}
            {payinOrder?.paymentCustomerUtr && (
              <div>{payinOrder.paymentCustomerUtr}</div>
            )}
          </Fragment>
        );
      },
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.CustomerRequisites),
    },
    {
      header: t(`${prefix}.customer_requisites`),
      valueGetter: (item) => {
        const payoutOrder = item as PayoutOrder;
        return (
          <RequisitesInfo
            cardholder={payoutOrder?.customerCardholder}
            bankId={payoutOrder?.bankId}
            paymentTypeId={payoutOrder?.paymentTypeId}
            cardInfo={payoutOrder?.customerCard}
            phone={payoutOrder?.customerPhone}
            swiftBic={payoutOrder?.customerSwiftBic}
            bic={payoutOrder?.customerBic}
            idCard={payoutOrder?.customerIdCard}
            beneficiaryName={payoutOrder?.customerBeneficiaryName}
            accountNumber={payoutOrder?.customerAccountNumber}
            email={payoutOrder?.customerEmail}
            name={payoutOrder?.customerName}
            expirationDate={payoutOrder?.customerExpirationDate}
            taxId={payoutOrder?.customerTaxId}
          />
        );
      },
      hidden:
        !isPayout ||
        !additionalColumns.includes(AdditionalOrderColumn.CustomerRequisites),
    },
    {
      header: t(`${prefix}.shop`),
      valueGetter: (item) => (
        <UserInfo name={formatUtils.formatName(item.shop)} id={item.shopId} />
      ),
      hidden: !additionalColumns.includes(AdditionalOrderColumn.Shop),
    },
    {
      header: t(`${prefix}.order_processed_by`),
      valueGetter: (item) => {
        const order = item as PayinOrder | PayoutOrder;
        return (
          <Fragment>
            {order.trader?.user && (
              <UserInfo
                name={order.trader.user.name}
                id={order.trader.user.id}
              />
            )}
            {order.p2pProviderOrder?.provider && (
              <Fragment>
                {isPayout &&
                  order.status === PayoutOrderStatus.TraderPayment && (
                    <Timer
                      endTime={orderUtils.getProviderPayoutOrderTimeout(
                        order as PayoutOrder,
                      )}
                    />
                  )}
                <UserInfo
                  name={order.p2pProviderOrder.provider.name}
                  id={order.p2pProviderOrder.provider.id}
                />
              </Fragment>
            )}
          </Fragment>
        );
      },
      hidden: !additionalColumns.includes(
        AdditionalOrderColumn.OrderProcessedBy,
      ),
    },
    {
      header: t(`${prefix}.trader`),
      valueGetter: (item) =>
        item.trader?.user && (
          <UserInfo name={item.trader.user.name} id={item.trader.user.id} />
        ),
      hidden: !additionalColumns.includes(AdditionalOrderColumn.Trader),
    },
    {
      header: t(`${prefix}.p2p_provider`),
      valueGetter: (item) => {
        const payinOrder = item as PayinOrder;
        return (
          payinOrder.p2pProviderOrder?.provider && (
            <UserInfo
              name={payinOrder.p2pProviderOrder.provider.name}
              id={payinOrder.p2pProviderOrder.provider.id}
            />
          )
        );
      },
      hidden: !additionalColumns.includes(AdditionalOrderColumn.P2PProvider),
    },
    {
      header: t(`${prefix}.bank`),
      valueKey: 'bank',
      hidden: !additionalColumns.includes(AdditionalOrderColumn.Bank),
    },
    {
      header: t(`${prefix}.merchant`),
      valueGetter: (item) => (
        <Fragment>
          <UserInfo name={item.merchant?.user.name} id={item?.merchantId} />
          {item.externalOrderId && (
            <div>
              <span>
                {t(`${prefix}.order_id`)}
                {':'}
              </span>
              <CopyTextId id={item.externalOrderId} />
            </div>
          )}
        </Fragment>
      ),
      hidden: !additionalColumns.includes(AdditionalOrderColumn.Merchant),
    },
    {
      header: t(`${prefix}.external_order_id`),
      valueGetter: (item) =>
        item.externalOrderId && <CopyTextId id={item.externalOrderId} />,
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.ExternalOrderId),
    },
    {
      header: t(`${prefix}.status`),
      valueGetter: (item) => <OrderStatusColumn order={item as PayinOrder} />,
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.StatusActive),
    },
    {
      header: t(`${prefix}.status`),
      valueGetter: (item) => (
        <PayoutOrderStatusColumn order={item as PayoutOrder} />
      ),
      hidden:
        !isPayout ||
        !additionalColumns.includes(AdditionalOrderColumn.StatusActive),
    },
    {
      header: t(`${prefix}.hold_completed`),
      valueGetter: (item) =>
        (item as PayoutOrder).holdTimeoutAt ? (
          <Timer endTime={orderUtils.getHoldTimeoutAt(item as PayoutOrder)} />
        ) : null,
      hidden:
        !isPayout ||
        !additionalColumns.includes(AdditionalOrderColumn.HoldCompleted),
    },
    {
      header: t(`${prefix}.reason`),
      valueGetter: (item) =>
        getCancelOrderReason((item as PayinOrder).statusDetails),
      hidden: !additionalColumns.includes(AdditionalOrderColumn.CancelReason),
    },
    {
      header: t(`${prefix}.status`),
      valueClassName: 'tw-w-[160px]',
      valueGetter: (item) => (
        <OrderResolveDisputeColumn order={item as PayinOrder} />
      ),
      hidden: !additionalColumns.includes(AdditionalOrderColumn.ResolveDispute),
    },
    {
      header: t(`${prefix}.dispute`),
      valueClassName: 'tw-w-[240px]',
      valueGetter: (item) => (
        <OrderStatusDetailsInfo order={item as PayinOrder} />
      ),
      hidden:
        !isPayin || !additionalColumns.includes(AdditionalOrderColumn.Dispute),
    },
    {
      header: t(`${prefix}.dispute_time`),
      valueGetter: (item) => (
        <OrderDisputeTimeColumn order={item as PayinOrder} />
      ),
      hidden:
        !isPayin ||
        !isManager ||
        !additionalColumns.includes(AdditionalOrderColumn.Dispute),
    },
    {
      header: t(`${prefix}.dispute`),
      valueClassName: 'tw-w-[240px]',
      valueGetter: (item) => (
        <PayoutOrderDisputeColumn order={item as PayoutOrder} />
      ),
      hidden:
        !isPayout || !additionalColumns.includes(AdditionalOrderColumn.Dispute),
    },
    {
      header: t(`${prefix}.actions`),
      valueClassName: 'tw-w-[120px]',
      valueGetter: (item) => (
        <OrderCancelledToDisputeColumn order={item as PayinOrder} />
      ),
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.CancelledToDispute),
    },
    {
      header: t(`${prefix}.actions`),
      valueClassName: 'tw-w-[120px]',
      valueGetter: (item) => (
        <PayoutOrderCancelledToDisputeColumn order={item as PayoutOrder} />
      ),
      hidden:
        !isPayout ||
        !additionalColumns.includes(AdditionalOrderColumn.CancelledToDispute),
    },
    {
      header: t(`${prefix}.automation_status`),
      valueClassName: 'tw-w-[25px]',
      valueGetter: (item) => <AutomationStatus order={item as PayinOrder} />,
      hidden:
        !isPayin ||
        !additionalColumns.includes(AdditionalOrderColumn.AutomationStatus),
    },
    {
      header: t(`${prefix}.validation`),
      valueClassName: 'tw-w-[120px]',
      valueGetter: (item) => (
        <PayoutOrderValidationColumn order={item as PayoutOrder} />
      ),
      hidden:
        !isPayout ||
        !additionalColumns.includes(AdditionalOrderColumn.Validation),
    },
    {
      header: t(`${prefix}.automation_status`),
      valueClassName: 'tw-w-[25px]',
      valueGetter: (item) => (
        <PayoutOrderAutomationStatusIcon order={item as PayoutOrder} />
      ),
      hidden:
        !isPayout ||
        !additionalColumns.includes(AdditionalOrderColumn.AutomationStatus),
    },
    {
      header: t(`${prefix}.requisites_verification_status`),
      valueGetter: (item) => {
        const payinOrder = item as PayinOrder;
        const verificationSuccess =
          payinOrder.status === OrderStatus.Cancelled &&
          payinOrder.statusDetails ===
            CancelStatusDetails.CancelledRequisitesVerificationOrder;
        const verificationInProgress =
          payinOrder.status === OrderStatus.CustomerConfirm;

        if (verificationSuccess) {
          return <CheckIcon color="success" />;
        } else if (verificationInProgress) {
          return <HourglassEmptyIcon color="primary" />;
        } else {
          return <WarningIcon color="error" />;
        }
      },
      hidden:
        !isPayin ||
        !additionalColumns.includes(
          AdditionalOrderColumn.RequisitesVerificationResult,
        ),
    },
  ];
};

const hideSensitiveData = (params: {
  isPayout: boolean;
  role?: UserRole;
  status: string;
}): boolean =>
  params.isPayout &&
  params.role === UserRole.Trader &&
  [PayoutOrderStatus.Requisites, PayoutOrderStatus.TraderAccept].includes(
    params.status as PayoutOrderStatus,
  );

const getProviderPayoutOrderTimeout = (order: PayoutOrder) => {
  const timeout = order.p2pProviderOrder?.provider?.payoutConfig?.orderTimeout;
  const p2pOrderCreatedAt = order.p2pProviderOrder?.createdAt;
  if (timeout) {
    return moment(p2pOrderCreatedAt).add(timeout, 'minutes').toDate();
  }
};

const getCancelOrderReason = (details: OrderStatusDetails) => {
  const { t } = i18next;
  const key = `order_cancelation_reason.${details}`;
  return i18next.exists(key) ? t(key as any) : key;
};

const getMaxTimeoutAt = (order: PayoutOrder) => {
  if (order.maxTimeoutAt) {
    return moment(order.maxTimeoutAt).toDate();
  }
  return moment(order.createdAt)
    .add(DEFAULT_PAYOUT_MAX_TIMEOUT, 'minutes')
    .toDate();
};

const getHoldTimeoutAt = (order: PayoutOrder) => {
  if (order.holdTimeoutAt) {
    return moment(order.holdTimeoutAt).toDate();
  }
};

const isMaxTimeoutOver = (order: PayoutOrder) =>
  moment(getMaxTimeoutAt(order)).isBefore(new Date());

const isStatusTimeoutOver = (order: PayinOrder) =>
  order.statusTimeoutAt && moment(order.statusTimeoutAt).isBefore(new Date());

const getExportOrdersColumnLabel = (column: string) =>
  i18next.t(`export_orders_column.${column}`, { defaultValue: column });

const isCrossFiatPayment = (order: Order) =>
  !!order.crossFiatCurrencyId && !!order.crossFiatCurrencyRate;

const getFormErrors = (error: AxiosError) => {
  const errors = validationUtils.getFormErrors(error);
  if (isString(errors.shopFee)) {
    errors.shopId = isString(errors.shopId)
      ? [errors.shopId, errors.shopFee].join('. ')
      : errors.shopFee;
    delete errors.shopFee;
  }
  return errors;
};

const getPayoutAutomationStatusLabel = (status: PayoutOrderAutomationStatus) =>
  i18next.t(`payout_order_automation_status.${status}`, {
    defaultValue: status,
  });

const getInternalStatusDetailsLabel = (details: OrderInternalStatusDetails) =>
  i18next.t(`order_internal_status_details.${details}`, {
    defaultValue: details,
  });

const getStatusReasonLabel = (reason: OrderStatusReason) =>
  i18next.t(`order_status_reason.${reason}`, {
    defaultValue: reason,
  });

const getDisputeTypeLabel = (type: OrderDisputeType) =>
  i18next.t(`order_dispute_type.${type}`, {
    defaultValue: type,
  });

export const orderUtils = {
  getCommonFilters,
  getCommonPayoutOrderFilters,
  getStatusFilterDefinition,
  getCallbackUrlStatusLabel,
  getStatusLabel,
  getStatusDate,
  getStatusDetailsLabel,
  getPayoutStatusDetailsLabel,
  getPayinOrderColumns,
  getPayoutOrderColumns,
  isStatusTimeoutOver,
  getOrderRequisitesVerificationStatusOptions,
  getOrderRequisitesVerificationStatusLabel,
  isCustomerPaid,
  isTraderPaid,
  getExportOrdersColumnLabel,
  isCrossFiatPayment,
  renderP2PProviderOrderRequisites,
  renderP2PProviderPayoutOrderRequisites,
  getMaxTimeoutAt,
  isMaxTimeoutOver,
  getProviderPayoutOrderTimeout,
  getHoldTimeoutAt,
  getFormErrors,
  getPayoutAutomationStatusLabel,
  getInternalStatusDetailsLabel,
  getStatusReasonLabel,
  getDisputeTypeLabel,
};
