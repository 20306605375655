import {
  SmartToy as SmartToyIcon,
  SmartToyOutlined as SmartToyOutlinedIcon,
  Sync as SyncIcon,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { noop } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

import { payoutOrdersApi } from 'api';
import { ConfirmButton } from 'components';
import {
  PayoutOrderAutomationStatus,
  PayoutOrderStatus,
  QueryKey,
} from 'enums';
import { useMutation, useUser } from 'hooks';
import { PayoutOrder } from 'types';
import { orderUtils } from 'utils';

type Props = {
  order: PayoutOrder;
};

export const PayoutOrderAutomationStatusIcon: React.FC<Props> = ({ order }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const { role, isManager } = useUser();

  const title = useMemo(
    () =>
      order.orderAutomationStatus
        ? orderUtils.getPayoutAutomationStatusLabel(order.orderAutomationStatus)
        : null,
    [order],
  );

  const { mutate: refreshStatus, isLoading } = useMutation(
    payoutOrdersApi.refreshAutomationStatusAsRole(role),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.DisputePayoutOrders);
      },
      notifierType: 'execute',
    },
  );

  const renderStatusIcon = useCallback(() => {
    switch (order.orderAutomationStatus) {
      case PayoutOrderAutomationStatus.Completed:
        return <SmartToyIcon color="success" />;
      case PayoutOrderAutomationStatus.InProgress:
        return <SmartToyOutlinedIcon color="info" />;
      case PayoutOrderAutomationStatus.Rejected:
        return <SmartToyOutlinedIcon color="warning" />;
      case PayoutOrderAutomationStatus.Cancelled:
        return <SmartToyIcon color="disabled" />;
      case PayoutOrderAutomationStatus.Error:
        return <SmartToyIcon color="error" />;
      default:
        return <div />;
    }
  }, [order]);

  const renderRefreshIcon = useCallback(
    () => (
      <Tooltip title={t('buttons.refresh')}>
        <div className="tw-ml-1 tw-cursor-pointer">
          <ConfirmButton onConfirm={() => refreshStatus(order.id)}>
            {({ onClick }) => (
              <SyncIcon
                color={isLoading ? 'disabled' : 'primary'}
                onClick={isLoading ? noop : onClick}
              />
            )}
          </ConfirmButton>
        </div>
      </Tooltip>
    ),
    [t, refreshStatus, order.id, isLoading],
  );

  if (!order.orderAutomationStatus) {
    return null;
  }

  return (
    <div className="tw-flex tw-items-center">
      <Tooltip title={title}>{renderStatusIcon()}</Tooltip>
      {isManager &&
        order.status === PayoutOrderStatus.Dispute &&
        order.orderAutomationStatus === PayoutOrderAutomationStatus.Cancelled &&
        renderRefreshIcon()}
    </div>
  );
};
