import { find } from 'lodash';
import { useMemo } from 'react';

import { META_KEY } from 'constants/meta.contants';

import { useUserContext } from './use-user-context.hook';

export const useMeta = () => {
  const { meta } = useUserContext();
  const telegramSupportBotUrl = useMemo(
    () => find(meta, { key: META_KEY.TELEGRAM_SUPPORT_BOT_URL })?.value,
    [meta],
  );
  const payoutMaxActiveOrders = useMemo(() => {
    const value = Number(
      find(meta, { key: META_KEY.PAYOUT_MAX_ACTIVE_ORDERS })?.value,
    );
    if (!isNaN(value)) {
      return value;
    }
  }, [meta]);
  const payinRequisitesVerificationRequired = useMemo(() => {
    const value = find(meta, {
      key: META_KEY.PAYIN_REQUISITES_VERIFICATION_REQUIRED,
    })?.value;

    return value === 'true';
  }, [meta]);
  const payinRequisitesVerificationExpirationAfter = useMemo(() => {
    const value = Number(
      find(meta, {
        key: META_KEY.PAYIN_REQUISITES_VERIFICATION_EXPIRATION_AFTER,
      })?.value,
    );
    if (!isNaN(value)) {
      return value;
    }
  }, [meta]);
  const traderCanCancelDispute = useMemo(() => {
    const value = find(meta, {
      key: META_KEY.TRADER_CAN_CANCEL_DISPUTE,
    })?.value;

    return value === 'true';
  }, [meta]);
  return {
    telegramSupportBotUrl,
    payoutMaxActiveOrders,
    payinRequisitesVerificationRequired,
    payinRequisitesVerificationExpirationAfter,
    traderCanCancelDispute,
  };
};
