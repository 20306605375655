import { CancelOutlined as CancelIcon } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { find, map } from 'lodash';
import moment from 'moment';
import React, { Fragment, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { payoutOrderAutomationApi, usersApi } from 'api';
import {
  DataGridColumnDefinition,
  CopyTextId,
  dataGridColumns,
  ConfirmButton,
  CrudPage,
} from 'components';
import {
  FilterDefinitionType,
  PayoutOrderAutomationStatus,
  PayoutOrderAutomationTab,
  PayoutRequisitesAutomationType,
  QueryKey,
} from 'enums';
import { useMutation, usePartialQuery, useQueryFilters, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import {
  FilterDefinition,
  PayoutOrderAutomation,
  PayoutOrderAutomationFilters,
} from 'types';
import {
  filtersUtils,
  payoutRequisitesAutomationUtils,
  orderUtils,
} from 'utils';

import { AutomationRequestsButton } from '.';

type Props = {
  tab: PayoutOrderAutomationTab;
};

export const PayoutOrderAutomationList: React.FC<Props> = ({ tab }) => {
  const queryClient = useQueryClient();

  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.payout_order_automation',
  });

  const { isManager, isAdminOrTechOperator } = useUser();

  const queryKey = useMemo(() => `${tab}-payout-order-automation`, [tab]);
  const queryResult = usePartialQuery(
    queryKey,
    payoutOrderAutomationApi.getPaginatedByStatus(tab),
    { useCachedCount: true },
  );

  const queryResultTraders = useQuery(
    QueryKey.UsersTraders,
    usersApi.getAllTraders,
    { enabled: isManager },
  );

  const { filters } = useQueryFilters<PayoutOrderAutomationFilters>({
    from: moment().subtract(1, 'w').startOf('d').toISOString(),
  });

  const { mutate: cancelPayoutOrderAutomation } = useMutation(
    payoutOrderAutomationApi.cancelActiveAutomation,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.ActivePayoutOrderAutomation);
      },
      notifierType: 'execute',
    },
  );

  const renderCancelAutomationAction = useCallback(
    (item: PayoutOrderAutomation) => (
      <ConfirmButton
        onConfirm={() => cancelPayoutOrderAutomation(item.id)}
        dialog={{ children: t('table.actions.cancel_automation_confirm') }}
      >
        {(props) => (
          <IconButton color="error" {...props}>
            <Tooltip title={t('table.actions.cancel_automation')}>
              <CancelIcon />
            </Tooltip>
          </IconButton>
        )}
      </ConfirmButton>
    ),
    [t, cancelPayoutOrderAutomation],
  );

  const columns = useMemo(
    (): DataGridColumnDefinition<PayoutOrderAutomation>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('table.columns.automation_type'),
        valueGetter: (item) =>
          item.requisitesAutomation?.type &&
          payoutRequisitesAutomationUtils.getTypeLabel(
            item.requisitesAutomation.type,
          ),
      },
      {
        header: t('table.columns.trader'),
        valueGetter: (item) => item.requisitesAutomation?.user?.name,
        hidden: !isManager,
      },
      {
        header: t('table.columns.order'),
        valueGetter: (item) => <CopyTextId id={item.orderId} />,
      },
      {
        header: t('table.columns.payment_id'),
        valueGetter: (item) =>
          item.paymentId && <CopyTextId id={item.paymentId} />,
        hidden: tab === PayoutOrderAutomationTab.Rejected,
      },
      {
        header: t('table.columns.status'),
        valueGetter: (item) =>
          orderUtils.getPayoutAutomationStatusLabel(item.status),
        hidden: tab !== PayoutOrderAutomationTab.All,
      },
      {
        header: t('table.columns.automation_requests'),
        valueGetter: (item) => (
          <AutomationRequestsButton automationId={item.id} />
        ),
        hidden: !isManager,
      },
      dataGridColumns.getActionsColumn({
        hidden:
          !isAdminOrTechOperator || tab !== PayoutOrderAutomationTab.Active,
        children: (item) => (
          <Fragment>{renderCancelAutomationAction(item)}</Fragment>
        ),
      }),
    ],
    [t, tab, renderCancelAutomationAction, isManager, isAdminOrTechOperator],
  );

  const filtersDefinitions: FilterDefinition<PayoutOrderAutomationFilters>[] =
    useMemo(
      () => [
        {
          label: t('filters.automation_type'),
          name: 'requisitesAutomationType',
          type: FilterDefinitionType.Select,
          options: map(PayoutRequisitesAutomationType, (value) => ({
            value,
            label: payoutRequisitesAutomationUtils.getTypeLabel(value),
          })),
          getDisplayName: payoutRequisitesAutomationUtils.getTypeLabel,
        },
        {
          label: t('filters.trader'),
          name: 'userId',
          type: FilterDefinitionType.User,
          users: queryResultTraders.data,
          getDisplayName: (id: string) =>
            find(queryResultTraders.data, { id })?.name,
          hidden: !isManager,
        },
        {
          label: t('filters.order_id'),
          name: 'orderId',
          type: FilterDefinitionType.Text,
          format: 'uuid',
        },
        {
          label: t('filters.payment_id'),
          name: 'paymentId',
          type: FilterDefinitionType.Text,
        },
        {
          label: t('filters.status'),
          name: 'status',
          type: FilterDefinitionType.Select,
          options: map(PayoutOrderAutomationStatus, (value) => ({
            value,
            label: orderUtils.getPayoutAutomationStatusLabel(value),
          })),
          getDisplayName: orderUtils.getPayoutAutomationStatusLabel,
          hidden: tab !== PayoutOrderAutomationTab.All,
        },
      ],
      [t, tab, isManager, queryResultTraders.data],
    );

  return (
    <CrudPage
      table={{
        queryResult,
        columns,
        paginated: true,
      }}
      filters={{
        filtersDefinitions: [
          ...filtersUtils.getCommonFilters(filters),
          ...filtersDefinitions,
        ],
      }}
    />
  );
};
