import styled from '@emotion/styled';
import AvTimerIcon from '@mui/icons-material/AvTimer';
import { Chip, ChipProps, SxProps, Tooltip, Typography } from '@mui/material';
import { isNumber } from 'lodash';
import { rgba } from 'polished';
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useTranslation } from 'react-i18next';

import { TranslationNamespace } from 'i18n';
import { formatDate } from 'utils';

type Props = {
  startTime?: Date;
  endTime?: Date;
  duration?: number;
  showWhenLess?: number;
  alertAfter?: number;
  showTimePassed?: boolean;
  hideTimeOver?: boolean;
  sx?: SxProps;
};

type TimerContainerProps = ChipProps & {
  alert?: boolean;
};

const TimerContainer = styled(Chip)<TimerContainerProps>`
  background: ${(props) =>
    rgba(
      props?.alert
        ? props?.theme?.palette.error.light
        : props?.theme?.palette.secondary.main,
      0.1,
    )};
  border: 0;
`;

export const Timer: React.FC<Props> = ({
  startTime,
  endTime: endTimeProp,
  duration,
  showWhenLess,
  alertAfter,
  showTimePassed,
  hideTimeOver,
  sx,
}) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'components.count_down',
  });

  const endTime: number = useMemo(
    () => Number(endTimeProp) || Number(startTime) + Number(duration),
    [duration, endTimeProp, startTime],
  );

  const [initialized, setInitialized] = useState(false);
  const [now, setNow] = useState(Date.now());
  const timePassed = useMemo(() => now - Number(startTime), [startTime, now]);
  const timeLeft = useMemo(() => endTime - now, [endTime, now]);
  const timeOver = useMemo(
    () => isNumber(timeLeft) && timeLeft <= 0,
    [timeLeft],
  );

  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const splitTime = useCallback(
    (milliseconds: number) => ({
      hours: Math.floor(milliseconds / 1000 / 3600),
      minutes: Math.floor(((milliseconds / 1000) % 3600) / 60),
      seconds: Math.floor((milliseconds / 1000) % 60),
    }),
    [],
  );

  const stop = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, []);

  const tick = useCallback(() => {
    if (timeOver) {
      stop();
    }
    setNow(Date.now());
  }, [timeOver, stop]);

  const roundTime = useCallback(
    (time: number) => String(time).padStart(2, '0'),
    [],
  );

  const time = useMemo(
    () => splitTime(showTimePassed ? timePassed : timeLeft),
    [showTimePassed, timePassed, timeLeft, splitTime],
  );

  const timerInfo = useMemo(() => {
    if (timeOver) {
      return t('timer_is_over');
    }
    if (time.hours >= 24) {
      return t('more_than_day');
    }
    const mmss = `${roundTime(time.minutes)}:${roundTime(time.seconds)}`;
    const hh = time.hours ? `${roundTime(time.hours)}:` : '';
    return `${hh}${mmss}`;
  }, [timeOver, time, t, roundTime]);

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
      if (!timeOver) {
        intervalRef.current = setInterval(tick, 1000);
      }
    }
  }, [initialized, timeOver, tick, stop]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => stop, []);

  const alert = useMemo(
    () =>
      isNumber(alertAfter) &&
      (showTimePassed ? timePassed >= alertAfter : timeLeft <= alertAfter),
    [alertAfter, timePassed, timeLeft, showTimePassed],
  );
  const fontColor = useMemo(() => (alert ? 'error' : 'secondary'), [alert]);

  const timer = useMemo(
    () => (
      <TimerContainer
        sx={sx}
        label={
          <div className="tw-flex tw-items-center">
            <AvTimerIcon color={fontColor} />
            <Typography color={fontColor}>{timerInfo}</Typography>
          </div>
        }
        variant="outlined"
        alert={alert}
      />
    ),
    [timerInfo, fontColor, alert, sx],
  );

  if (isNumber(showWhenLess) && timeLeft > showWhenLess) {
    return null;
  }

  if (hideTimeOver && timeOver) {
    return null;
  }

  if (time.hours >= 24) {
    return (
      <Tooltip title={`${t('dispute_from')}${formatDate(startTime)}`}>
        {timer}
      </Tooltip>
    );
  }

  return timer;
};
