import { Typography } from '@mui/material';
import cx from 'classnames';
import { useFormikContext } from 'formik';
import { map, find } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';

import { payoutRequisitesAutomationApi } from 'api';
import { FormikSelect } from 'components';
import { QueryKey } from 'enums';
import { PayoutAutomationAccountInfoDetails } from 'features/payout-requisites';
import { useMoney, useMutation, usePrevious } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { PayoutOrder, PayoutRequisitesAutomation } from 'types';

type Props = {
  order: PayoutOrder;
};

export const ConfirmPaymentAutomation: React.FC<Props> = ({ order }) => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.orders.confirm_payment_dialog',
  });

  const formik = useFormikContext<{ automationId: string }>();
  const prevAutomationId = usePrevious(formik.values.automationId);
  const { getFormattedMoney } = useMoney();

  const queryResultAutomations = useQuery(QueryKey.PayoutOrderAutomations, () =>
    payoutRequisitesAutomationApi.getOrderAutomations(order.id),
  );

  const accountInfoMutation = useMutation(
    payoutRequisitesAutomationApi.getAccountInfo,
    {
      notifierType: 'execute',
    },
  );

  const getLabelWithAccountInfo = useCallback(
    (automation: PayoutRequisitesAutomation) =>
      automation.accountInfo?.balance
        ? `${automation.name} (${getFormattedMoney({
            value: automation.accountInfo.balance,
            fiatCurrencyId: automation.balanceFiatCurrencyId,
            symbol: true,
          })})`
        : automation.name,
    [getFormattedMoney],
  );

  const selectedAutomation = useMemo(
    () =>
      find(
        queryResultAutomations.data,
        (automation) => automation.id === formik.values.automationId,
      ),
    [formik.values.automationId, queryResultAutomations.data],
  );

  const accountInfoAutomation = useMemo(
    () => accountInfoMutation.data! || selectedAutomation,
    [accountInfoMutation.data, selectedAutomation],
  );

  const getOptionLabel = useCallback(
    (automation: PayoutRequisitesAutomation) => {
      if (accountInfoMutation.data?.id === automation.id) {
        return getLabelWithAccountInfo(accountInfoAutomation);
      }
      return getLabelWithAccountInfo(automation);
    },
    [accountInfoAutomation, accountInfoMutation.data, getLabelWithAccountInfo],
  );

  const automationOptions = useMemo(
    () =>
      map(queryResultAutomations.data, (automation) => ({
        label: getOptionLabel(automation),
        value: automation.id,
      })),
    [queryResultAutomations, getOptionLabel],
  );

  useEffect(() => {
    if (
      accountInfoMutation.data &&
      prevAutomationId !== formik.values.automationId
    ) {
      accountInfoMutation.reset();
    }
  }, [accountInfoMutation, prevAutomationId, formik.values.automationId]);

  return (
    <div>
      <FormikSelect
        name="automationId"
        label={t('automation')}
        options={automationOptions}
        fullWidth
      />
      {formik.values.automationId && (
        <div
          className={cx('tw-mt-2', {
            'tw-flex tw-items-center': !accountInfoAutomation,
          })}
        >
          <Typography sx={{ mr: 1 }} variant="subtitle1">{`${t(
            'account_info',
          )}:`}</Typography>
          <PayoutAutomationAccountInfoDetails
            automation={accountInfoAutomation}
            disabled={accountInfoMutation.isLoading}
            getAccountInfo={() => {
              accountInfoMutation.mutate(formik.values.automationId);
            }}
          />
        </div>
      )}
    </div>
  );
};
